export default {
    install: function(Vue) {
        Vue.prototype.$permission = {
            permissionList: [],
            //判断当前操作是否在权限内
            is: function(api) {
                // let apiurl = api.toLowerCase(); //转小写
                // console.log(apiurl);
                if(api==''){
                    return true;
                }else if(window.app.userInfo.act_url){
                    this.permissionList = window.app.userInfo.act_url;
                    if (this.permissionList.indexOf(api)>-1) {
                        return true;
                    }
                    return false;
                }
            }
        };
    }
};
