import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default new Router({
    routes: [
        {
            path: '/',
            name: '登录',
            component: () => import('./views/login.vue')
        },

        {
            path: '/index',
            name: '首页',
            component: () => import('@/views/index.vue'),
            children: [
                {
                    path: '/guide',
                    name: '引导页',
                    component: () => import('@/views/guide.vue'),
                },
                {
                    path: '/overview',
                    name: '统计概况',
                    component: () => import('@/views/indexBox/overview.vue'),
                },{
                    path: '/staffManage',
                    name: '业务员管理',
                    component: () => import('@/views/staffManage/index.vue'),
                },{
                    path: '/businessIndex',
                    name: '市场部员工',
                    component: () => import('@/views/staffManage/businessIndex.vue'),
                }, { 
                    path: '/printOrder',
                    name: 'printOrder',
                    component: () => import('@/views/orderBox/printOrder.vue')
                }, { //会员订单打印
                    path: '/printOrderMember',
                    name: 'printOrderMember',
                    component: () => import('@/views/orderBox/printOrderMember.vue')
                }, { //续费订单打印
                    path: '/printRenew',
                    name: 'printRenew',
                    component: () => import('@/views/orderBox/printRenew.vue')
                }, {
                    path: '/cashAudit',
                    name: '审核管理',
                    component: () => import('@/views/auditBox/cashAudit.vue'),
                }, {
                    path: '/CategoryMemberList',
                    name: '品类会员列表',
                    component: () => import('@/views/cateMemberBox/CategoryMemberList.vue'),
                }, {
                    path: '/cateMemberAdd',
                    name: '品类会员列表',
                    component: () => import('@/views/cateMemberBox/cateMemberAdd.vue'),
                }, {
                    path: '/shop',
                    name: '普通商品列表',
                    component: () => import('@/views/shopBox/shopList.vue'),
                }, {
                    path: '/membershop',
                    name: '会员商品列表',
                    component: () => import('@/views/shopBox/membershopList.vue'),
                },
                {
                    path: '/ysalesList',
                    name: '预售商品列表',
                    component: () => import('@/views/houseOverview/ysalesList.vue'),
                }, {
                    path: '/PointChangeList',
                    name: '积分兑换列表',
                    component: () => import('@/views/shopBox/PointChangeList.vue'),
                }, {
                    path: '/shopType',
                    name: '商品分类',
                    component: () => import('@/views/shopBox/shopType.vue'),
                }, {
                    path: '/shopSpec',
                    name: '商品规格',
                    component: () => import('@/views/shopBox/shopSpec.vue'),
                }, {
                    path: '/shopScene',
                    name: '商品场景',
                    component: () => import('@/views/shopBox/shopScene.vue'),
                }, {
                    path: '/shopAdd',
                    name: '商品编辑',
                    component: () => import('@/views/shopBox/shopAdd.vue'),
                }, , {
                    path: '/PointAdd',
                    name: '商品编辑',
                    component: () => import('@/views/shopBox/PointAdd.vue'),
                }, {
                    path: '/order',
                    name: '商品订单',
                    component: () => import('@/views/orderBox/order.vue'),
                }, {
                    path: '/ysales',
                    name: '预售订单',
                    component: () => import('@/views/orderBox/ysales.vue'),
                }, {
                    path: '/PointChangeOrder',
                    name: '积分兑换订单',
                    component: () => import('@/views/orderBox/PointChangeOrder.vue'),
                }, {
                    path: '/virtualBuyOrder',
                    name: '虚拟订单',
                    component: () => import('@/views/orderBox/virtualBuyOrder.vue'),
                }, {
                    path: '/reneworder',
                    name: '续费订单',
                    component: () => import('@/views/orderBox/reneworder.vue'),
                },
                {
                    path: '/memberUser',
                    name: '会员用户',
                    component: () => import('@/views/memberBox'),

                }, {
                    path: '/ordinaryUser',
                    name: '普通用户',
                    component: () => import('@/views/memberBox/user.vue'),

                }, {
                    path: '/memberDet',
                    name: '会员管理详情',
                    component: () => import('@/views/memberBox/memberDet.vue'),
                }, {
                    path: '/RechargeManagement',
                    name: '充值管理',
                    component: () => import('@/views/memberBox/RechargeManagement.vue'),

                }, {
                    path: '/couponManagement',
                    name: '优惠券管理',
                    component: () => import('@/views/memberBox/couponManagement.vue'),
                }, {
                    path: '/integralManagement',
                    name: '积分管理',
                    component: () => import('@/views/memberBox/integralManagement.vue'),
                }, {
                    path: '/config',
                    name: '配置管理',
                    component: () => import('@/views/configBox'),
                }, {
                    path: '/reCharge',
                    name: '充值配置',
                    component: () => import('@/views/configBox/reCharge.vue'),
                }, {
                    path: '/coupon',
                    name: '优惠券',
                    component: () => import('@/views/configBox/coupon.vue'),
                }, {
                    path: '/banner',
                    name: '轮播图',
                    component: () => import('@/views/configBox/banner.vue'),
                }, {
                    path: '/active',
                    name: '活动列表',
                    component: () => import('@/views/configBox/activeBox/index.vue'),
                }, {
                    path: '/activeAdd',
                    name: '编辑活动',
                    component: () => import('@/views/configBox/activeBox/add.vue'),
                }, {
                    path: '/notice',
                    name: '公告管理',
                    component: () => import('@/views/noticeBox'),
                }, {
                    path: '/service',
                    name: '客服管理',
                    component: () => import('@/views/serviceBox'),
                }, {
                    path: '/message',
                    name: '消息提醒',
                    component: () => import('@/views/messageBox'),
                }, {
                    path: '/admin',
                    name: '账号管理',
                    component: () => import('@/views/adminBox'),
                }, {
                    path: '/cash',
                    name: '提现列表',
                    component: () => import('@/views/CashBox'),
                }]
        }, {
            path: '/setting',
            name: '后台',
            component: () => import('@/views/setting/index.vue'),
            children: [{
                path: '/setRole',
                name: '角色管理',
                component: () => import('@/views/setting/role.vue'),
            }, {
                path: '/setJurisdiction',
                name: '权限管理',
                component: () => import('@/views/setting/jurisdiction.vue'),
            }, {
                path: '/setNode',
                name: '节点管理',
                component: () => import('@/views/setting/nodeSet.vue'),
            }, {
                path: '/setPersonnel',
                name: '人员管理',
                component: () => import('@/views/setting/personnel.vue'),
            },]
        }]
})
